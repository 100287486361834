// bower:scss
// endbower

$color-primary: #45ADA8;

*:active, *:focus {
	outline: none !important;
}

body {
	font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 18px;
	line-height: 1.4;
	color: #333;
}

h1, h2, h3 {
	font-weight: bold;}

h1 {
	font-size: 200%;
}

h2 {
	font-size: 130%;
	font-weight: normal;
}

a {
	border-bottom: solid 1px darken($color-primary, 8%);
	color: $color-primary;
}

.btn {
	border: none;
	background: transparent;
	font-family: inherit;
	font-size: 16px;
	line-height: 1;
	cursor: pointer;
}

main {
	width: 50%;
	margin: 48px auto;

	.btn {
		display: inline-block;
		padding: 12px 16px;
		background: $color-primary;
		border-radius: 4px;
		color: #fff;

		&:hover {
			background: darken($color-primary, 6%);
		}
	}

	p, .btn-list {
		margin-bottom: 2em;
	}

	@media (max-width: 767px) {
		width: 90%;

		.btn {
			width: 100%;
			display: block;
			margin: 8px 0;
		}
	}
}

.slide-menu {
	background: $color-primary;
	color: #fff;

	.slider {
		top: 48px;
	}

	.controls {
		overflow: hidden;
	}

	a {
		color: #fff;

		&:hover {
			background: darken($color-primary, 6%);
		}
	}

	.btn {
		float: right;
		color: #fff;
		background: darken($color-primary, 12%);
		padding: 8px;

		&:hover {
			background: darken($color-primary, 12% + 6%);
		}

		& + .btn {
			margin-right: 1px;
		}
	}

}

#test-menu-left {
	padding: 16px;
}