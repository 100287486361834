*:active, *:focus {
  outline: none !important;
}

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  color: #333;
}

h1, h2, h3 {
  font-weight: bold;
}

h1 {
  font-size: 200%;
}

h2 {
  font-size: 130%;
  font-weight: normal;
}

a {
  border-bottom: solid 1px #39908c;
  color: #45ADA8;
}

.btn {
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
}

main {
  width: 50%;
  margin: 48px auto;
}

main .btn {
  display: inline-block;
  padding: 12px 16px;
  background: #45ADA8;
  border-radius: 4px;
  color: #fff;
}

main .btn:hover {
  background: #3c9793;
}

main p, main .btn-list {
  margin-bottom: 2em;
}

@media (max-width: 767px) {
  main {
    width: 90%;
  }
  main .btn {
    width: 100%;
    display: block;
    margin: 8px 0;
  }
}

.slide-menu {
  background: #45ADA8;
  color: #fff;
}

.slide-menu .slider {
  top: 48px;
}

.slide-menu .controls {
  overflow: hidden;
}

.slide-menu a {
  color: #fff;
}

.slide-menu a:hover {
  background: #3c9793;
}

.slide-menu .btn {
  float: right;
  color: #fff;
  background: #34817e;
  padding: 8px;
}

.slide-menu .btn:hover {
  background: #2b6b68;
}

.slide-menu .btn + .btn {
  margin-right: 1px;
}

#test-menu-left {
  padding: 16px;
}
